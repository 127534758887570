<template>
	<el-dialog
		:title="titleDialog"
		:visible="showImportDialog"
		@close="close"
		@open="create"
		width="50%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group text-center" :class="{ 'has-danger': errors.file }">
						<el-upload
							ref="upload"
							:headers="headers"
							:action="`${appUrlBackEnd}/api/items/import`"
							:show-file-list="true"
							:auto-upload="false"
							:multiple="false"
							:on-error="errorUpload"
							:limit="1"
							:on-success="successUpload"
						>
							<el-button slot="trigger" type="info"
								>Seleccione un archivo (xlsx)</el-button
							>
						</el-upload>
						<small
							class="form-control-feedback"
							v-if="errors.file"
							v-text="errors.file[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12">
					<el-button @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading_submit"
						>Procesar</el-button
					>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import AppConfig from 'Constants/AppConfig';

export default {
	props: ['showImportDialog'],
	data() {
		return {
			appUrlBackEnd: AppConfig.appUrlBackEnd,
			headers: {
				Authorization: null,
			},
			loading_submit: false,
			titleDialog: null,
			resource: 'items',
			errors: {},
			form: {},
		};
	},
	created() {
		this.initForm();
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				file: null,
			};
		},
		create() {
			this.titleDialog = 'Importar carta';
		},
		async submit() {
			this.loading_submit = true;
			this.headers.Authorization = `Bearer ${this.$store.getters.getToken}`;
			await this.$refs.upload.submit();
			this.loading_submit = false;
		},
		close() {
			this.$emit('update:showImportDialog', false);
			this.initForm();
		},
		successUpload(response) {
			if (response.success) {
				this.$message.success(response.message);
				this.$eventHub.$emit('reloadData', this.resource);
				this.$refs.upload.clearFiles();
				this.close();
			} else {
				this.$message({ message: response.message, type: 'error' });
			}
		},
		errorUpload(response) {
			console.log(response);
		},
	},
};
</script>
